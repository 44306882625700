import styled from "styled-components"

import { mixin, url } from "~/libs/style"

export const PageTopButton: React.FC = () => <ButtonLink href="#">ページトップ</ButtonLink>

const ButtonLink = styled.a`
  display: block;
  ${mixin.borderRadius}
  width: 50px;
  height: 50px;
  overflow: hidden;
  text-indent: 100%;
  white-space: nowrap;
  pointer-events: none;
  background-color: rgba(0, 0, 0, 0.4);
  background-image: ${url("/gachi/assets/img/totop.png")};
  background-repeat: no-repeat;
  background-position: center 14px;
  background-size: 45px auto;
`
