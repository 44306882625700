import React from "react"
import styled from "styled-components"

export const Container: React.FC = ({ children }) => {
  return <Wrapper>{children}</Wrapper>
}

const Wrapper = styled.div`
  padding-bottom: 50px;
  @media (orientation: portrait) {
    padding-bottom: calc(50 * (100vw / 320));
  }
`
