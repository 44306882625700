import React from "react"
import styled from "styled-components"

import { useTrackingNew } from "~/contexts/Tracking"
import { color, url } from "~/libs/style"

export type HeaderProps = {
  hasBackButton?: boolean
  hasNotice?: boolean
  backUrl?: string
  backTitle?: string
  headerTitle?: string
  iconText?: string
  baseUrl?: string
}

export const Header: React.FC<HeaderProps> = ({
  hasBackButton = false,
  hasNotice = false,
  backUrl = "/",
  backTitle = "ゲームTOP",
  headerTitle = "無料ゲーム",
  iconText = "ポータルTOP",
  baseUrl = "/"
}) => {
  const { analyticsTrack } = useTrackingNew()
  const categoryTrack = "有料Top_ヘッダー"
  return (
    <Wrapper>
      {hasBackButton && (
        <BackLink
          href={backUrl}
          onClick={(event: React.MouseEvent<HTMLAnchorElement>) => analyticsTrack(event, categoryTrack, "TOP")}
        >
          {backTitle}
        </BackLink>
      )}
      <Text>
        <h1>
          <Img src={`${baseUrl}assets/img/h1.svg`} alt={headerTitle} />
        </h1>
      </Text>
      {hasNotice && (
        <NoticeIcon>
          <IconText>お知らせ</IconText>
        </NoticeIcon>
      )}
      <HomeIcon
        as="a"
        href="https://portal.auone.jp/"
        onClick={(event: React.MouseEvent<HTMLAnchorElement>) => analyticsTrack(event, categoryTrack, "ポータルTOP")}
      >
        <IconText>{iconText}</IconText>
      </HomeIcon>
    </Wrapper>
  )
}

const Wrapper = styled.header`
  position: relative;
  display: flex;
  height: 50px;
  background-color: ${color.white};
  border-bottom: 1px solid ${color.choke};
  padding: 0 130px;
  margin: 0 auto;
  justify-content: center;
`

const BackLink = styled.a`
  position: absolute;
  display: block;
  width: 65px;
  height: 100%;
  overflow: hidden;
  text-indent: 100%;
  white-space: nowrap;
  left: 0;
  &::before {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 13px;
    display: block;
    width: 11px;
    height: 11px;
    margin: auto;
    content: "";
    border: 0 solid ${color.steelGray};
    border-width: 2px 0 0 2px;
    transform: rotate(-45deg);
  }
`

const Text = styled.div`
  display: flex;
  flex: 1;
  align-items: center;
  justify-content: center;
  font-size: 3.38164251vw;
  font-weight: bold;
  h1 {
    min-width: 100px;
  }
`

const Icon = styled.div`
  position: absolute;
  display: flex;
  align-items: flex-end;
  justify-content: center;
  width: 65px;
  height: 100%;
  background-repeat: no-repeat;
  background-position: center 10px;
`

const HomeIcon = styled(Icon)`
  right: 0;
  background-image: ${url("/gachi/assets/img/home.svg")};
  background-size: 20px 21px;
`

const NoticeIcon = styled(Icon)`
  right: 60px;
  background-image: ${url("/gachi/assets/img/notice.svg")};
  background-size: 21px 21px;
`

const IconText = styled.p`
  padding-bottom: 7px;
  font-size: 10px;
  font-weight: bold;
  color: ${color.silverGray};
  transform: scale(0.7, 0.7);
`
const Img = styled.img`
  max-width: 100%;
  height: auto;
  display: block;
`
