import React from "react"
import styled from "styled-components"

import { useTrackingNew } from "~/contexts/Tracking"
import { color, mixin } from "~/libs/style"

export const Footer: React.FC = () => {
  const { analyticsTrack } = useTrackingNew()
  const analyticsCategory = "SiteTop_フッター"
  return (
    <Wrapper>
      <Nav>
        <Item>
          <Link
            href="https://portal.auone.jp/"
            onClick={(event: React.MouseEvent<HTMLAnchorElement>) => analyticsTrack(event, analyticsCategory, "TOP")}
          >
            TOP
          </Link>
        </Item>
        <Item>
          <Link
            href="https://sp-web.search.auone.jp/"
            onClick={(event: React.MouseEvent<HTMLAnchorElement>) => analyticsTrack(event, analyticsCategory, "検索")}
          >
            検索
          </Link>
        </Item>
        <Item>
          <Link
            href="https://touch.eznavi.auone.jp/transfer/"
            onClick={(event: React.MouseEvent<HTMLAnchorElement>) =>
              analyticsTrack(event, analyticsCategory, "乗換・地図")
            }
          >
            乗換・地図
          </Link>
        </Item>
        <Item>
          <Link
            href="https://tenki.auone.jp/"
            onClick={(event: React.MouseEvent<HTMLAnchorElement>) => analyticsTrack(event, analyticsCategory, "天気")}
          >
            天気
          </Link>
        </Item>
        <Item>
          <Link
            href="https://sp.fortune.auone.jp/"
            onClick={(event: React.MouseEvent<HTMLAnchorElement>) => analyticsTrack(event, analyticsCategory, "占い")}
          >
            占い
          </Link>
        </Item>
      </Nav>
      <Nav>
        <Item>
          <Link
            href="https://www.mediba.jp/privacy/advertising"
            onClick={(event: React.MouseEvent<HTMLAnchorElement>) =>
              analyticsTrack(event, analyticsCategory, "お客様情報の収集と外部送信について")
            }
          >
            お客様情報の収集と外部送信について
          </Link>
        </Item>
      </Nav>
      <Nav>
        <Item>
          <Link
            href="https://article.auone.jp/terms-of-use/"
            onClick={(event: React.MouseEvent<HTMLAnchorElement>) =>
              analyticsTrack(event, analyticsCategory, "利用規約")
            }
          >
            利用規約
          </Link>
        </Item>
        <Item>
          <Link
            href="https://www.mediba.jp/privacy"
            onClick={(event: React.MouseEvent<HTMLAnchorElement>) =>
              analyticsTrack(event, analyticsCategory, "プライバシーポリシー")
            }
          >
            プライバシーポリシー
          </Link>
        </Item>
      </Nav>
      <Copyright>
        <small>©mediba</small>
      </Copyright>
    </Wrapper>
  )
}

const Wrapper = styled.footer`
  padding-top: 20px;
  background-color: ${color.whiteSmoke};
  margin-top: 20px;
`

const Nav = styled.ul`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 15px;
`

const Item = styled.li`
  position: relative;
  text-align: center;
  background-color: transparent;
  &::after {
    position: absolute;
    top: 0;
    right: 0;
    display: inline-block;
    width: 1px;
    height: 15px;
    content: "";
    border-right: 1px solid ${color.orchidGray};
  }
  &:last-child {
    &::after {
      display: none;
    }
  }
`

const Link = styled.a`
  display: block;
  padding: 2px 10px;
  font-size: 11px;
`

const Copyright = styled.p`
  ${mixin.maxWidth}
  height: 44px;
  margin: 0 auto;
  font-size: 10px;
  line-height: 44px;
  text-align: center;
`
