import React, { useEffect, useState } from "react"
import styled, { css } from "styled-components"

import { PageTopButton } from "~/components/PageTopButton"
import { useTrackingNew } from "~/contexts/Tracking"

const easeOutExpo = (t: number) => {
  return t === 1 ? 1 : 1 * (-Math.pow(2, (-10 * t) / 1) + 1)
}

const scrollToTop = (callback: () => void) => {
  const startTime = window.performance.now()
  const duration = 500
  const threshold = 2000
  const scrollFrom = window.pageYOffset > threshold ? threshold : window.pageYOffset
  const scrollTo = 0

  let rafId = 0

  const move = (now: number) => {
    const time = Math.min(1, (now - startTime) / duration)
    const easing = easeOutExpo(time)
    const moveTo = easing * (scrollTo - scrollFrom) + scrollFrom

    window.scroll(0, moveTo)

    if (window.pageYOffset === scrollTo || now - startTime >= duration) {
      window.scroll(0, scrollTo)
      window.cancelAnimationFrame(rafId)
      callback?.()
      return
    }

    rafId = window.requestAnimationFrame(move)
  }

  rafId = window.requestAnimationFrame(move)
}

const useScrollState = (offset: number) => {
  const [visible, setVisible] = useState(false)
  const handleScroll = () => {
    setVisible(window.pageYOffset > offset)
  }

  useEffect(() => {
    window.addEventListener("scroll", handleScroll)
    return () => {
      window.removeEventListener("scroll", handleScroll)
    }
  }, [offset])

  return visible
}

type PageTopProps = {
  displayOffset: number
}

export const PageTop: React.FC<PageTopProps> = ({ displayOffset }) => {
  const visible = useScrollState(displayOffset)
  const { analyticsTrack } = useTrackingNew()

  return (
    <Wrapper
      visible={visible}
      onClick={(event: React.MouseEvent<HTMLDivElement>) => {
        scrollToTop(() => {
          //do nothing)
        })
        analyticsTrack(event, "SiteTop_フローティングボタン", "ページトップ")
      }}
    >
      <PageTopButton />
    </Wrapper>
  )
}

const Wrapper = styled.div<{ visible: boolean }>`
  position: fixed;
  right: 10px;
  bottom: 70px;
  z-index: 10;
  opacity: 0;
  transition: opacity 0.5s;
  ${({ visible }) => visible && WrapperCssWhenVisible}
`

const WrapperCssWhenVisible = css`
  pointer-events: auto;
  opacity: 1;
`
